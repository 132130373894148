import React, { useEffect } from 'react';
import RoutesApp from './routes/Routes';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './style.css';

const App = () => {
  return (
    <RoutesApp/>
  )
}

export default App