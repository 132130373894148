import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import useFetch from '../../hooks/useFetch';

import { classNames } from 'primereact/utils';
import { Editor } from 'primereact/editor';
import wpmanager from '../../assets/img/wp.png';

import style from './Help.module.css';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tree } from 'primereact/tree';

const Help = () => {
    const [selecaoHelp,setSelecaoHelp] = useState(false);
    const [dataTree,setDataTree] = useState([]);
    const [selectionTree,setSelectionTree] = useState();
    const [tituloApp,setTituloApp] = useState("");
    const [tituloModulo,setTituloModulo] = useState("");
    const [tituloFuncionalidade,setTituloFuncionalidade] = useState("");
    const [tituloAcao,setTituloAcao] = useState("");
    const [traducao,setTraducao] = useState([]);
    // parametros capturados da url
    const {idioma,app,modulo,funcionalidade,acao} = useParams();
    // sistema para chamadas ao backend
    const {http_get_criteria: getCriteriaHelp, data} = useFetch('help/external');
    const {http_get_criteria: getCriteriaHelpTree, data: resDataTree} = useFetch('help/tree-external');
    // navegação entre rotas
    // const navigate = useNavigate()
    // tipo de pagina
    // controle de formulário
    const { setValue, control, reset, formState: { errors} } = useForm(
        {
            mode: "onChange",
            defaultValues: {
                conteudo: ''
            }
        }
    );
    // ao carregar esta pagina e existir um 'id', realizar chamada no backend e buscar o registro
    useEffect(() => {
        var filters = [];
        filters.push({columnName: 'codigoIdioma', searchType: 'equals', value: idioma})
        filters.push({columnName: 'codigoAplicacao', searchType: 'equals', value: app})
        var key = app;
        if(modulo!==undefined){
            key = key + '-' + modulo;
            filters.push({columnName: 'codigoModulo', searchType: 'equals', value: modulo})
        }
        if(funcionalidade!==undefined){
            key = key + '-' + funcionalidade;
            filters.push({columnName: 'codigoFuncionalidade', searchType: 'equals', value: funcionalidade})
        }
        if(acao!==undefined){
            key = key + '-' + acao;
            filters.push({columnName: 'codigoAcao', searchType: 'equals', value: acao})
        }
        setSelectionTree(key)
        getCriteriaHelp(filters)
        getCriteriaHelpTree(filters)
    },[])
    const buscarHelp = () => {
        const pathH = selectionTree.split("-");
        var filters = [];
        filters.push({columnName: 'codigoIdioma', searchType: 'equals', value: idioma})
        filters.push({columnName: 'codigoAplicacao', searchType: 'equals', value: app})
        if(pathH[1]!==undefined){
            filters.push({columnName: 'codigoModulo', searchType: 'equals', value: pathH[1]})
        }
        if(pathH[2]!==undefined){
            filters.push({columnName: 'codigoFuncionalidade', searchType: 'equals', value: pathH[2]})
        }
        if(acao!==pathH[3]){
            filters.push({columnName: 'codigoAcao', searchType: 'equals', value: pathH[3]})
        }
        getCriteriaHelp(filters)
    }
    // resultado da busca do help
    useEffect(() => {
        if (data) {
            reset(data);
            const pathH = selectionTree.split("-");
            setTituloApp(getName(traducao,pathH[0]))
            setTituloModulo(getName(traducao,pathH[1]))
            setTituloFuncionalidade(getName(traducao,pathH[2]))
            setTituloAcao(getName(traducao,pathH[3]))
        }
    },[data])
    useEffect(() => {
        if (resDataTree){
            var cdata = [];
            var data = [];
            var translateKey = [];
            resDataTree.forEach(
                i => {
                    if (i.codigoAplicacao !== undefined && cdata[i.codigoAplicacao] == null){
                        cdata[i.codigoAplicacao] = []
                        translateKey[i.codigoAplicacao] = i.nome
                    }
                    if (i.codigoModulo !== undefined && cdata[i.codigoAplicacao][i.codigoModulo] == null){
                        cdata[i.codigoAplicacao][i.codigoModulo] = []
                        translateKey[i.codigoModulo] = i.nome
                    }
                    if (i.codigoFuncionalidade !== undefined && cdata[i.codigoAplicacao][i.codigoModulo][i.codigoFuncionalidade] == null){
                        cdata[i.codigoAplicacao][i.codigoModulo][i.codigoFuncionalidade] = []
                        translateKey[i.codigoFuncionalidade] = i.nome
                    }
                    if (i.codigoAcao !== undefined && cdata[i.codigoAplicacao][i.codigoModulo][i.codigoFuncionalidade][i.codigoAcao] == null){
                        cdata[i.codigoAplicacao][i.codigoModulo][i.codigoFuncionalidade][i.codigoAcao] = i
                        translateKey[i.codigoAcao] = i.nome
                    }
                }
            )
            Object.keys(cdata).forEach(
                i => {
                    var app = {
                        key: i,
                        label: translateKey[i],
                        children: []
                    }
                    Object.keys(cdata[i]).forEach(
                        j => {
                            var m = {
                                key: i+'-'+j,
                                label: translateKey[j],
                                children: []
                            }
                            Object.keys(cdata[i][j]).forEach(
                                k => {
                                    var f = {
                                        key: i+'-'+j+'-'+k,
                                        label: translateKey[k],
                                        children: []
                                    }
                                    Object.keys(cdata[i][j][k]).forEach(
                                        v => {
                                            var a = {
                                                key: i+'-'+j+'-'+k+'-'+v,
                                                label: translateKey[v]
                                            }
                                            f.children.push(a);
                                        }
                                    )
                                    m.children.push(f);
                                }
                            )
                            app.children.push(m);
                        }
                    )
                    data.push(app);
                }
            )
            setDataTree(data)
            setTraducao(translateKey)
            const pathH = selectionTree.split("-");
            setTituloApp(getName(translateKey,pathH[0]))
            setTituloModulo(getName(translateKey,pathH[1]))
            setTituloFuncionalidade(getName(translateKey,pathH[2]))
            setTituloAcao(getName(translateKey,pathH[3]))
        }
    },[resDataTree])
    const getName = (list,name) => {
        if (list[name]){
            return list[name]
        }
        return ""
    }
    // busca mensagem de erro para o campo do formulãrio
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-invalid">{errors[name].message}</small>
    };
    // página
    return (
        <>
            <div className={style["container-bar"]}>
                <div className="logo">
                    <img src={wpmanager} style={{ top: "16px", left: "21px", position: "absolute", height: "30px" }} alt="wpmanager" />
                </div>
                <div className={style["container-center-center"]}>
                    {/* <span className={style["title"]}>Workplanner Manager</span> */}
                    <span className={style["title"]}>Ajuda Online</span>
                </div>
                <div className={style["container-start-center"]}>
                    <Button icon="pi pi-search" label="Pesquisar"  className="p-button-text p-mr-5" onClick={() => {setSelecaoHelp(true)}} />
                </div>
            </div>
            <div className={style["container-center-centertit"]}>
                <div className={style["divtit"]}>
                    <div className={style["divtit-tit"]}>Aplicação</div>
                    <div className={style["divtit-tit2"]}>{tituloApp}</div>
                </div>
                <div className={style["divtit"]}>
                    <div className={style["divtit-tit"]}>Módulo</div>
                    <div className={style["divtit-tit2"]}>{tituloModulo}</div>
                </div>
                <div className={style["divtit"]}>
                    <div className={style["divtit-tit"]}>Funcionalidade</div>
                    <div className={style["divtit-tit2"]}>{tituloFuncionalidade}</div>
                </div>
                <div className={style["divtit"]}>
                    <div className={style["divtit-tit"]}>Ação</div>
                    <div className={style["divtit-tit2"]}>{tituloAcao}</div>
                </div>
            </div>
            <div className={style.container}>
                <form className={style.form} >
                    <div className={classNames(style.contentcard)}>
                        <div className={classNames(style.card,style.conteudo)}>
                            <div className={classNames(style.field,style.field100)} style={{flex: '1 1 1e-09px'}}>
                                <Controller name="conteudo" control={control} rules={{ required: 'Campo obrigatório.'}} render={({ field, fieldState }) => (
                                    <>
                                        <Editor readOnly showHeader={false} id={field.name} {...field} onTextChange={(e) => setValue('conteudo',e.htmlValue)} style={{height:'100%',flex:'1 1 1e-09px'}} />
                                    </>
                                )} />
                                {getFormErrorMessage('conteudo')}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Dialog header="Menu Ajuda Online" visible={!!selecaoHelp} modal style={{ width:'450px', height:'550px'}} onHide={() => setSelecaoHelp(false)}>
                <div style={{marginBottom:'15px'}}>
                    <span>Selecione a opção:</span>
                </div>
                <Tree value={dataTree} selectionMode="single" selectionKeys={selectionTree} onSelectionChange={(e) => {setSelectionTree(e.value)}} style={{width: "100%",height:"80%",overflow: "auto"}}/>
                <div style={{display:"flex",justifyContent:"end",width:"100%"}}>
                    <Button style={{marginTop:'15px'}} label="Confirmar" onClick={()=>{buscarHelp();setSelecaoHelp(false)}}/>
                </div>
            </Dialog>
        </>
    ) 
}

export default Help