import { Fragment } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Help from '../pages/Help/Help'

const RoutesApp = () => {
  return (
    <BrowserRouter>
      <Fragment>
        <Routes>
          <Route exact path="/help/:idioma/:app" element={<Help/>} />
          <Route exact path="/help/:idioma/:app/:modulo" element={<Help/>} />
          <Route exact path="/help/:idioma/:app/:modulo/:funcionalidade" element={<Help/>} />
          <Route exact path="/help/:idioma/:app/:modulo/:funcionalidade/:acao" element={<Help/>} />
        </Routes>
      </Fragment>
    </BrowserRouter>
  )
}

export default RoutesApp