import { useEffect, useState } from 'react'
// import useAuth from '../hooks/useAuth'

const useFetch = (url) => {
    // const {token,usuario} = useAuth();
    const baseUrl = 'https://appmanager-backend.weforcloud.com.br/';
    const route = url;
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState(null);
    const [config,setConfig] = useState();
    const [id,setId] = useState("");
    const [criteria,setCriteria] = useState();
    const [page,setPage] = useState(false);
    const processArgs = (args) => {
        var str = "";
        let entries = Object.entries(args)
        entries.map( ([key, val]) => {
            if (str === ""){
                str += key + "=" + val;
            }else {
                str += "&" + key + "=" + val;
            }
        });
        return str;
    }
    const processCriteria = (args) => {
        var str = "";
        // console.log(args);
        args.map( val => {
            if ( val.columnName && val.searchType && val.value){
                if (str === ""){
                    str += val.columnName+"."+val.searchType+"="+val.value;
                }else {
                    str += "&"+val.columnName+"."+val.searchType+"="+val.value;
                }
            }
        });
        return str;
    }
    const http_post = (obj) => {
        setConfig(
            {
                method: "POST",
                headers: {
                    "Content-type": "application/json"
                },
                body: JSON.stringify(obj)
            }
        )
    }
    const http_update = (obj) => {
        setConfig(
            {
                method: "PUT",
                headers: {
                    "Content-type": "application/json"
                },
                body: JSON.stringify(obj)
            }
        )
    }
    const http_get = (id) => {
        setId(id);
        setConfig(
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json"
                }
            }
        )
    }
    const http_get_criteria = (criteriax) => {
        setCriteria(criteriax);
        // console.log(criteriax)
        setConfig(
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json"
                }
            }
        )
    }
    const http_page = (args) => {
        setPage(args);
        setConfig(
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json"
                }
            }
        )
    }
    const http_delete = (id) => {
        setId(id);
        setConfig(
            {
                method: "DELETE",
                headers: {
                    "Content-type": "application/json"
                }
            }
        )
    }
    useEffect(
        () => {
            async function fetchData(){
                if (config){
                    setLoading(true);
                    var url = baseUrl+route;
                    if (id && (config.method === "GET" || config.method === "DELETE")){
                        url += "/"+id;
                        setId(null)
                    } else if ( page ){
                        url += "/list-by-page?"+processArgs(page);
                        setPage(null)
                    } else if ( criteria ){
                        url += "?"+processCriteria(criteria);
                        setCriteria(null)
                    }
                    var cconfig = config;
                    // if (token){
                    //     cconfig.headers["Authorization"] = "Bearer " + token;
                    //     cconfig.headers["usuarioid"] = usuario.id;
                    // }
                    const result = await fetch(url,cconfig);
                    const json = await result.json();
                    setData(json);
                    setLoading(false);
                }
            }
            fetchData();
        }
    ,[config])
    return {http_post,http_update,http_get,http_get_criteria,http_delete,http_page,data,loading}
}

export default useFetch